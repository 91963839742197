<template>
    <div>
        <top>
            <div class="tc cfff">
                <div class="fs42 pt140">人才招聘</div>
                <div class="fs20 mt20 " style="opacity: 0.8;">有关安迩平台人才招聘</div>
            </div>
            
        </top>
        <div class="rowjc">
            <div style="margin-top: -130px; min-height: calc(100vh - 730px);" class="box">
                <div class="rowic  warp pt30 pb30">
                    <div class="recruitmentBox mt30 ml30   pr33 pl33 pt50 pb60 crowjbic" @click="goPage(item.id)" v-for="(item,index) in recruitList" :key="index">
                        <img class="h90 w90" src="@/assets/img/img/gangweiimg.png" alt="">
                        <div class="fs22  moveTitle fwb yc1 w240 tc" >{{item.title}}</div>
                        <div class="lh26 moveContent yc3"> {{item.synopsis}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 60px;"></div>
        <bottom></bottom>
    </div>
</template>
<script>
    
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        components: {
            bottom,
            top
        },
        data() {
            return {
                recruitList:[],//
            }
        },
        mounted() {
            this.getRecruitList()
        },
        methods: {
            goPage(id){
                this.$router.push({
                    path:'/recuitInfo',
                    query:{
                        id
                    }
                })
            },
            getRecruitList() {//获取招聘列表
                this.$axios({
                    url: 'getMessageList',
                    data: {
                        type: 2
                    }

                }).then(res => {

                    if (res.data.code == 0) {
                        this.recruitList = res.data.data

                    } else {
                        this.recruitList = []
                    }
                })
            },
        },
    }
</script>
<style>
    #app {
        background-color: #F2F5F7;
    }

    .box {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 0 100px 0 100px;

    }
    .recruitmentBox {
        width: 290px;
        height: 350px;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 5px;
    }

    .recruitmentBox:hover {
        cursor: pointer;
        background: linear-gradient(180deg, #3CE1AE 0%, #2ED087 100%);
    }
    .recruitmentBox:hover .moveContent {
        color: #FFFFFF;
    }

    .moveTitle {
        color: #000;
    }

    .recruitmentBox:hover .moveTitle {
        color: #FFFFFF;
    }

</style>